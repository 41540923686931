.AboutContainer {
  background-color: #242424;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: absolute;
  top: 0;
}

.header {
  margin-top: 1rem;
}

.aboutIntro {
  width: 80vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 5rem;
}

.textContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.aboutMeText {
  line-height: 1.8rem;
  font-size: 20px;
  margin: 0rem 7rem 0rem 0rem;
}
.pictureContainer {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.aboutImg {
  width: 80%;
  height: 350px;
  border-radius: 3rem;
  /* margin: 3rem 0rem 5rem 0rem; */
}

.btnLink {
  font-family: monospace;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  background-color: white;
  color: black;
  cursor: pointer;
  border-radius: 1rem;

  padding: 16px 32px;
  margin: 1rem 1rem 1rem 1rem;
}

@media screen and (max-width: 1435px) {
  .aboutMeText {
    margin: 0rem 4rem 0rem 0rem;
  }
}

@media screen and (max-width: 1250px) {
  .textContainer {
    gap: 3rem;
  }

  .aboutMeText {
    margin: 0rem 4rem 0rem 0rem;
  }
}

@media screen and (max-width: 1080px) {
  .aboutMeText {
    margin: 0rem 4rem 0rem 0rem;
  }
}

@media screen and (max-width: 980px) {

  .header {
    margin-top: 10rem;
  }

  .aboutIntro {
    flex-direction: column;
    /* flex-wrap: wrap; */
    margin-top: 1rem;
    margin-bottom: 8rem;
  }

  .textContainer {
    width: 100%;
    /* display: flex;
    flex-direction: column; */
    align-items: center;
    text-align: center;
}

.aboutMeText {
  margin: 0rem 0rem 0rem 0rem;

}

.pictureContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.aboutImg {
  height: 300px;
}


}

@media screen and (max-width: 980px) {

  .header {
    margin-top: 6rem;
  }
}

@media screen and (max-width: 768px) {
  .header {
    margin-top: 7rem;
  }
}

@media screen and (max-width: 390px) {
}
