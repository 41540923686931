/* .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    background-color: grey !important;
} */



.MuiTypography-root {
    text-align: center !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    font-family: monospace,sans-serif !important;
    font-size: 2rem !important;
    line-height: 1.25 !important;

}
