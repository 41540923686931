.ContactPageStyles {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background-color: #242424;
  min-height: 100vh;
  width: 100%;
  font-size: calc(10px + 2vmin);
  color: white;
  position: absolute;
  top: 0;
}

.pageHeader {
  margin-top: 8rem;
}

.contactInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 50%;
  min-height: 10rem;
  margin-bottom: 3rem;
}

.contactInfoText {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.contactInfoBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-around;
  border: 1px solid yellow;
  padding: 1rem;
  border-radius: 1rem;
  width: 40%;
}


.formBox {
  width: 55%;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  margin-bottom: 7rem;
}

.inputRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 2rem 0;
  gap: 1rem;
}

.nameBox {
  font-family: monospace;

  height: 4rem;
  box-sizing: border-box;
  background-color: white;
  width: 50%;
  border-radius: 1rem;
  padding-left: 20px;
}

.emailBox {
  font-family: monospace;
  box-sizing: border-box;
  background-color: white;
  width: 50%;
  height: 4rem;
  border-radius: 1rem;
  padding-left: 20px;
}

.messageBox {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 1rem;
  resize: none;
}

.submitBtn {
  font-family: monospace;
  font-size: 1rem;
  font-weight: bold;
  background-color: white;
  color: black;
  padding: 16px 32px;
  margin-left: auto;
  margin-right: auto;
  min-width: 20%;
  min-height: 10%;
  border-radius: 1rem;
  margin-top: 2rem;
  cursor: pointer;
}

.contactModalText {
  border: 1px solid transparent;
  text-align: center;
  margin-top: 7%;
  /* margin-bottom: 50%; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
}

.error-text {
  font-size: 1.35rem;
  line-height: 1.7rem;
}

.closeBtn {
  float: right;
  cursor: pointer;
}

@media screen and (max-width: 1400px) {
  .pageHeader {
    margin-top: 8rem;
  }

  .formBox {
    min-width: 75%;
  }
}

@media screen and (max-width: 1200px) {
  .contactInfo {
    width: 60%;
  }
}


@media screen and (max-width: 1100px) {
.pageHeader {
  margin-top: 8rem;
}

.contactInfo {
  width: 70%;
}
}


@media screen and (max-width: 900px) {
  .pageHeader {
    margin-top: 7rem;
  }

  .contactInfo {
    width: 80%;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .formBox {
    width: 75%;
    margin-top: 1.5rem;
  }

  .inputRow {
    flex-direction: column;
    gap: 2rem;
  }

  .nameBox {
    width: 100%;
  }

  .emailBox {
    width: 100%;
  }

  .submitBtn {
    min-width: 35%;
    margin-top: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .pageHeader {
    margin-top: 9rem;
  }

  .contactInfo {
    flex-direction: column;
    align-items: center;
    margin-top: 0rem;
    gap: .5rem;
  }

  .contactInfoBox {
    width: 75%;
  }
}
