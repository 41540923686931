/* * {
  background-color: black;
} */
html {
  scroll-behavior: smooth;
}



.Home {
  position: absolute;
  top: 0;
  background-color: #242424;
  box-shadow: inset 0 0 10rem 3rem  black;
  min-height: 100vh;
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
  
}

@media screen and (max-width: 856px) {

}

