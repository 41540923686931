.footerContainer {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 0;
    /* top: 0; */
}

.footer {
    margin-bottom: 1rem;
    font-size: 10px;
    color: white;
    text-align: center;
    cursor: default;
}