.homeIntroContainer {  
  display: flex;
  flex-direction: row;
  margin: auto;
  align-items: center;
  z-index: 1;
  gap: 3rem;
}

.name {
  font-family: monospace;
  z-index: 1;
}

.title {
  font-family: monospace;
  z-index: 1;
}



@media screen and (max-width: 856px) {

  .homeIntroContainer {    
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    z-index: 1;
  }

  .socialsContainer {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 650px) {
  .name {
    margin-bottom: 4rem;
  }
}

@media screen and (max-width: 500px) {
  .nameContainer {
    text-align: center;
    margin-left: 3rem;
    margin-right: 3rem;
  }
}


@media screen and (max-width: 390px) {
  .homeIntroContainer {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    z-index: 1;
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .socialsContainer {
    margin-top: 2rem;
  }
}

