.ProjectsPageStyles {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background-color: #242424;
  min-height: 100vh;
  width: 100%;
  font-size: calc(10px + 2vmin);
  color: white;
  position: absolute;
  top: 0;
}

.workHeader {
  padding-top: 8rem;
}

/* project display section layout */
.project-section-area {
  display: grid;
  grid-template-columns: 35vw 35vw;
  grid-auto-flow: row;
  gap: 8rem;
  justify-items: center;
  align-items: center;
  margin: 5rem 0 10rem 0;
}

/* container for each project */
.project-container {
  /* display: flex; */
  /* flex-direction: column; */
  width: 100%;
  min-height: 20rem;
}

/* styles for project picture and effects */
.project-picture {
  background-color: rgb(40, 40, 51);
  color: white;
  min-height: 22rem;
  max-height: 22px;
  width: 100%;
  border-radius: 1rem;
  overflow: hidden;
  justify-content: center;
  display: flex;
  flex-direction: row;
  position: relative;
  text-align: center;
  cursor: default;
  opacity: 0.7;
}

.modalInfoContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalTitle {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.modalPictureContainer {
  width: 100%;
  max-height: 40%;
  text-align: center;
}

.modalPicture {
  width: 65%;
  height: 100%;
}

.modalText {
  margin-top: 1rem;
  margin-bottom: .5rem;
}

.linkBox {
  /* margin-top: 1rem; */
  width: 100%;
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  /* background-color: yellow;   */
}

/* .githubMark {
  position: absolute;
  bottom: 1rem;
  left: 3rem;
  z-index: 1;
}

.livesiteMark {
  position: absolute;
  bottom: 1rem;
  right: 3rem;
  z-index: 1;
} */

.project-picture .mask {
  width: 100%;
  position: absolute;
  min-height: 22rem;
  max-height: 22px;
  overflow: hidden;
  top: 0;
  left: 0;
}

.project-picture h4 {
  color: #fff;
  text-align: center;
  position: relative;
  font-size: 2rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.8);
  margin: 20px 0 0 0;
}

.project-picture p {
  margin-top: 1.5rem;
  font-size: 1.5rem;
  position: relative;
  color: white;
  background: rgba(0, 0, 0, 0.44);
  padding: 1rem;
  text-align: center;
}

.project-picture a.info {
  display: inline-block;
  text-decoration: none;
  padding: 7px 14px;
  background: #000;
  color: #fff;
  text-transform: uppercase;
  box-shadow: 0 0 1px #000;
}

.project-picture:hover {
  top: -10px;
  transition: top ease 0.5s;
  background-blend-mode: soft-light;
}

.view-first .mask {
  opacity: 0;
  background-image: linear-gradient(
    to top,
    rgb(255, 249, 0, 0),
    60%,
    rgba(238, 255, 4)
  );
  transition: all 0.4s ease-in-out;
}

.view-first p {
  transform: translateY(100px);
  opacity: 0;
  transition: all 0.2s linear;
}


/* hover effect for info display */
.view-first:hover .mask {
  opacity: 1;
}

.view-first:hover p {
  opacity: 1;
  transform: translateY(0px);
}
.view-first:hover p {
  transition-delay: 0.1s;
}


.project-picture img {
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.45) !important;
  
}

.closeProjectBtn {
  float: right;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .project-section-area {
    grid-template-columns: 75vw;
    gap: 6rem;
  }
}

@media screen and (max-width: 900px) {
  .workHeader {
    padding-top: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .workHeader {
    padding-top: 4rem;
  }

  .headerInfo {
    margin-right: 8rem;
    margin-left: 8rem;
  }

  .project-picture {
    min-height: 20rem;
  }

  .project-picture .mask {
    min-height: 20rem;
  }
}

@media screen and (max-width: 550px) {
  .workHeader {
    padding-top: 5rem;
  }

  .headerInfo {
    margin-right: 4rem;
    margin-left: 4rem;
  }

  .project-picture {
    min-height: 18rem;
  }

  .project-picture .mask {
    min-height: 18rem;
  }
}
